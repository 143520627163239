<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6">
        <CardAnalyticSupportTracker />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import CardAnalyticSupportTracker from '@/components/ui/analytics/CardAnalyticSupportTracker.vue'

export default {
  components: {
    BRow,
    BCol,
    CardAnalyticSupportTracker,
  },
  directives: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
